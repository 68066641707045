import React from "react"
import { createRoot } from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Provider } from "react-redux"
import "./index.css"
import './i18n'
import { store } from "./app/store"

import App from "./Test"
import { ErrorPage } from "./pages/ErrorPage"
import { LoginPage } from "./pages/LoginPage"
import { RedefinePasswordPage } from "./pages/RedefinePasswordPage"
import { CollaboratorPage } from "./pages/CollaboratorPage"
import { DashboardPage } from "./pages/DashboardPage"
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute"
import { SecurityCodePage } from "./pages/SecurityCodePage"
import { ResetPasswordPage } from "./pages/ResetPasswordPage"
import { ReportPage } from "./pages/ReportPage"

const router = createBrowserRouter([
    {
        path: "/",
        element: <LoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "validate",
        element: <SecurityCodePage />
    },
    {
        path: "app",
        element: <App />,
    },
    {
        path: "redefine-password",
        element: <PrivateRoute>
            <RedefinePasswordPage />
        </PrivateRoute>
    },
    {
        path: "reset-password",
        element: <PrivateRoute>
            <ResetPasswordPage />
        </PrivateRoute>
    },
    {
        path: "dashboard",
        element: <PrivateRoute>
            <DashboardPage />
        </PrivateRoute>,
    },
    {
        path: "collaborators",
        element: <PrivateRoute>
            <CollaboratorPage />
        </PrivateRoute>,
    },
    {
        path: "report",
        element: <PrivateRoute>
            <ReportPage />
        </PrivateRoute>,
    }
])

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
)