import { I18n } from "../components/I18n/I18n.jsx"
import { Sidebar } from "../components/Sidebar/Sidebar"
import { Select } from "../components/Select/Select"
import { Button } from "../components/Button/Button.jsx"
import { useTranslation } from "react-i18next"
import { FaArrowRightLong } from "react-icons/fa6"
import React, { useState } from "react"
import { MdAdd } from "react-icons/md"
import { PiFilePdf } from "react-icons/pi";


export function ReportPage() {
    const { t } = useTranslation()

    const months = [
        { value: "jan", label: t("january") },
        { value: "feb", label: t("february") },
        { value: "mar", label: t("march") },
        { value: "apr", label: t("april") },
        { value: "may", label: t("may") },
        { value: "jun", label: t("june") },
        { value: "jul", label: t("july") },
        { value: "aug", label: t("august") },
        { value: "sep", label: t("september") },
        { value: "oct", label: t("october") },
        { value: "nov", label: t("november") },
        { value: "dec", label: t("december") }
    ]

    const formats = [
        { value: "pdf", label: "PDF", icon: <PiFilePdf></PiFilePdf>}
    ]

    const d = new Date()
    const day = d.getUTCDate()
    const month = d.getUTCMonth()
    const year = d.getUTCFullYear()

    const [fromDay, setFromDay] = useState(day.toString())
    const [toDay, setToDay] = useState(day.toString())
    const [fromMonth, setFromMonth] = useState(months[month].value)
    const [toMonth, setToMonth] = useState(months[month].value)
    const [fromYear, setFromYear] = useState(year.toString())
    const [toYear, setToYear] = useState(year.toString())

    const commands = [
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
    ]

    return (
        <div className="flex bg-h2v-dashboard-background h-screen overflow-hidden">
            <Sidebar />
            <div className="flex flex-col w-full h-full overflow-auto">
                <div className="flex items-center px-6 lg:px-12 justify-between py-6">
                    <div className="flex flex-row items-center">
                        <h1 className="text-black font-poppins font-medium text-base lg:text-h2vtext">{"Relatórios"}</h1>
                    </div>
                    <div className="flex flex-row space-x-6">
                        <Button
                            children={"LOG de Comandos"}
                            className="self-center bg-h2v-green border-2 border-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg"
                        />
                        <Button
                            children={"Produção de H2"}
                            className="self-center bg-white border-2 border-h2v-green font-poppins text-base text-h2v-green font-medium py-2 px-4 w-48 rounded-lg"
                        />
                        <Button
                            children={"Produção de H2"}
                            className="self-center bg-white border-2 border-h2v-green font-poppins text-base text-h2v-green font-medium py-2 px-4 w-48 rounded-lg"
                        />
                    </div>
                    <div className="flex items-center">
                        <I18n />
                    </div>
                </div>
                <div className="px-6 lg:px-6">
                    <div className="border-t border-gray-300 w-full" />
                </div>
                <div className="flex flex-row bg-white mt-4 mx-4 py-4 px-6 rounded-xl items-center justify-between">
                    <div className="flex flex-row items-center space-x-4">
                        <p>{"Exportar Relatório"}</p>
                        <Select
                            value={fromDay}
                            options={Array.from({ length: 31 }, (_, index) => {
                                const number = (index + 1).toString().padStart(2, "0")
                                return {
                                    value: number,
                                    label: number
                                }
                            })}
                            onChange={(e) => setFromDay(e.target.value)}
                            className="appearance-none cursor-pointer w-16 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 px-4 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <Select
                            value={fromMonth}
                            options={months}
                            onChange={(e) => setFromMonth(e.target.value)}
                            className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <Select
                            value={fromYear}
                            options={Array.from({ length: 2024 - 1900 + 1 }, (_, index) => {
                                const number = (1900 + index).toString()
                                return {
                                    value: number,
                                    label: number
                                }
                            })}
                            onChange={(e) => setFromYear(e.target.value)}
                            className="appearance-none cursor-pointer w-24 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <p>{t("until")}</p>
                        <Select
                            value={toDay}
                            options={Array.from({ length: 31 }, (_, index) => {
                                const number = (index + 1).toString().padStart(2, "0")
                                return {
                                    value: number,
                                    label: number
                                }
                            })}
                            onChange={(e) => setToDay(e.target.value)}
                            className="appearance-none cursor-pointer w-16 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 px-4 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <Select
                            value={toMonth}
                            options={months}
                            onChange={(e) => setToMonth(e.target.value)}
                            className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <Select
                            value={toYear}
                            options={Array.from({ length: 2024 - 1900 + 1 }, (_, index) => {
                                const number = (1900 + index).toString()
                                return {
                                    value: number,
                                    label: number
                                }
                            })}
                            onChange={(e) => setToYear(e.target.value)}
                            className="appearance-none cursor-pointer w-24 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <p>{"Formato"}</p>
                        <Select
                            options={formats}
                            className="appearance-none cursor-pointer w-24 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                    </div>
                    <Button
                        children={"Exportar"}
                        className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg"
                    />
                </div>
                <div className="flex flex-col bg-white mt-6 mb-20 mx-4 py-4 px-6 rounded-xl h-full overflow-hidden">
                    <div className="relative overflow-y-auto no-scrollbar">
                        <div className="sticky top-0 bg-white z-10">
                            <table className="min-w-full table-fixed">
                                <thead>
                                    <tr>
                                        <th className="w-1/4 text-left py-2 px-4 font-medium">Tipo de Comando</th>
                                        <th className="w-1/4 text-center py-2 px-4 font-medium">Parâmetro</th>
                                        <th className="w-1/4 text-center py-2 px-4 font-medium">Situação</th>
                                        <th className="w-1/4 py-2 px-4"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                        <table className="min-w-full table-fixed">
                            <tbody className="divide-y divide-gray-300">
                                {commands.map((item, index) => (
                                    <tr key={index} className="flex py-4">
                                        <td className="w-1/4 text-center py-2 px-4 flex items-center">{item.command}</td>
                                        <td className="w-1/4 text-center py-2 px-4 flex items-center justify-center space-x-4">
                                            <p>{item.from}%</p>
                                                <FaArrowRightLong />
                                            <p>{item.to}%</p>
                                        </td>
                                        <td className="w-1/4 py-2 px-4 flex justify-center items-center">
                                            <div className={`w-2 h-2 rounded-full ${item.status === t("waiting") ? "bg-yellow-500" : ""} mr-2`}></div>
                                            {item.status}
                                        </td>
                                        <td className="w-1/4 text-right py-2 px-4">
                                            <Button
                                                children={<MdAdd className="w-6 h-6" />}
                                                className="bg-gray-200 rounded-lg p-2"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
