import { createSlice } from "@reduxjs/toolkit"
import {
    listUsers,
    addUser,
    getUser,
    updateUser,
    updateUserEmail,
    archiveUser,
    confirmEmailUpdate
} from "./userActions"

const initialState = {
    users: [],
    user: null,
    loading: false,
    errors: {
        listing: null,
        gettingUser: null,
        addingUser: null,
        updatingUser: null,
        updatingEmail: null,
        archiving: null,
        confirmingEmail: null,
    },
    success: {
        addingUser: false,
        updatingUser: false,
        updatingEmail: false,
        archiving: false,
        confirmEmail: false,
    }
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUserState: (state) => {
            state.user = null
            state.users = []
            state.errors = initialState.errors
            state.success = initialState.success
        },
        resetArchivingSuccess(state) {
            state.success.archiving = false
        },
        resetUpdatingUserSuccess(state) {
            state.success.updatingUser = false
        },
        resetAddingUserSuccess(state) {
            state.success.addingUser = false
        },
        resetUpdatingEmailSuccess(state) {
            state.success.updatingEmail = false
        }
    },
    extraReducers: (builder) => {
        builder
            // listUsers
            .addCase(listUsers.pending, (state) => {
                state.loading = true
                state.errors.listing = null
            })
            .addCase(listUsers.fulfilled, (state, action) => {
                state.loading = false
                state.users = action.payload
            })
            .addCase(listUsers.rejected, (state, action) => {
                state.loading = false
                state.errors.listing = action.payload || 'Failed to fetch users.'
            })
            // addUser
            .addCase(addUser.pending, (state) => {
                state.loading = true
                state.errors.addingUser = null
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.loading = false
                state.users.push(action.payload)
                state.success.addingUser = true
            })
            .addCase(addUser.rejected, (state, action) => {
                state.loading = false
                state.errors.addingUser = action.payload || 'Failed to add user.'
            })
            // getUser
            .addCase(getUser.pending, (state) => {
                state.loading = true
                state.errors.gettingUser = null
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state, action) => {
                state.loading = false
                state.errors.gettingUser = action.payload || 'Failed to get user.'
            })
            // updateUser
            .addCase(updateUser.pending, (state) => {
                state.loading = true
                state.errors.updatingUser = null
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loading = false
                state.success.updatingUser = true
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false
                state.errors.updatingUser = action.payload || 'Failed to update user.'
            })
            // updateUserEmail
            .addCase(updateUserEmail.pending, (state) => {
                state.loading = true
                state.errors.updatingEmail = null
            })
            .addCase(updateUserEmail.fulfilled, (state, action) => {
                state.loading = false
                const index = state.users.findIndex(user => user.id === action.payload.id)
                if (index !== -1) {
                    state.users[index].email = action.payload.email
                    state.success.updatingEmail = true
                }
            })
            .addCase(updateUserEmail.rejected, (state, action) => {
                state.loading = false
                state.errors.updatingEmail = action.payload || 'Failed to update email.'
            })
            // confirm email
            .addCase(confirmEmailUpdate.pending, (state) => {
                state.loading = true
                state.errors.confirmingEmail = null
            })
            .addCase(confirmEmailUpdate.fulfilled, (state, action) => {
                state.loading = false
                state.confirmEmail = true
            })
            .addCase(confirmEmailUpdate.rejected, (state, action) => {
                state.loading = false
                state.errors.confirmingEmail = action.payload || 'Failed to confirm email.'
            })
            // archiveUser
            .addCase(archiveUser.pending, (state) => {
                state.loading = true
                state.errors.archiving = null
            })
            .addCase(archiveUser.fulfilled, (state, action) => {
                state.loading = false
                const index = state.users.findIndex(user => user.id === action.payload.id)
                if (index !== -1) {
                    state.users[index].status = action.payload.status
                }
                state.success.archiving = true
            })
            .addCase(archiveUser.rejected, (state, action) => {
                state.loading = false
                state.errors.archiving = action.payload || 'Failed to archive user.'
            })
    },
})

export const {
    resetUserState,
    resetAddingUserSuccess,
    resetArchivingSuccess,
    resetUpdatingEmailSuccess,
    resetUpdatingUserSuccess,
 } = userSlice.actions
export default userSlice.reducer
