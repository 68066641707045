import { useState, useRef } from "react"

export function CodeInput({ length = 6, onChange }) {
    const [values, setValues] = useState(Array(length).fill(""))
    const inputsRef = useRef([])

    const handleChange = (e, index) => {
        const value = e.target.value.toUpperCase()
        if (/^[0-9]$/.test(value) || value === "") {
            const newValues = [...values]
            newValues[index] = value
            setValues(newValues)
            onChange(newValues.join(""))

            if (value && index < length - 1) {
                inputsRef.current[index + 1].focus()
            }
        }
    }

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !values[index] && index > 0) {
            inputsRef.current[index - 1].focus()
        }
    }

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text").slice(0, length)
        const newValues = pasteData.split("").map((char, index) => {
            return /^[0-9]$/.test(char) ? char : values[index]
        })

        setValues(newValues)
        onChange(newValues.join(""))

        // Focus on the last filled input or the next available input
        const nextIndex = newValues.findIndex((val) => val === "")
        if (nextIndex !== -1) {
            inputsRef.current[nextIndex].focus()
        } else {
            inputsRef.current[length - 1].focus()
        }
    }

    return (
        <div className="flex justify-center space-x-2">
            {values.map((value, index) => (
                <input
                    key={index}
                    ref={(el) => (inputsRef.current[index] = el)}
                    type="text"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                    className="w-12 h-12 text-2xl font-semibold text-center border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
            ))}
        </div>
    )
}
