import { configureStore } from "@reduxjs/toolkit"
import authReducer from "../features/auth/authSlice"
import userReducer from "../features/user/userSlice"
import sideBarReducer from "../features/sideBar/sideBarSlice"
import panelReducer from "../features/panel/panelSlice"


export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        sideBar: sideBarReducer,
        panel: panelReducer
    }
})